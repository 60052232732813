import React from 'react';
import { Button } from '@material-ui/core';
import ThreeDHubs from '../../images/3DHubs.png';
import ThreeDiligent from '../../images/3Diligent.png';
import Facebook from '../../images/Facebook.png';
import Formlabs from '../../images/Formlabs.png';
import Hubspot from '../../images/Hubspot.png';
import Instagram from '../../images/Instagram.png';
import LinkedIn from '../../images/LinkedIn.png';
import TrelloDev from '../../images/TrelloDev.png';
import TrelloRD from '../../images/TrelloRD.png';
import TrelloSales from '../../images/TrelloSales.png';
import TrelloSocial from '../../images/TrelloSocial.png';
import Twitter from '../../images/Twitter.png';

const getPic = (pic) => {
  switch(pic) {
    case '3DHubs':
      return ThreeDHubs;
    case '3Diligent':
      return ThreeDiligent;
    case 'Facebook':
      return Facebook;
    case 'Formlabs':
      return Formlabs;
    case 'Hubspot':
      return Hubspot;
    case 'Instagram':
      return Instagram;
    case 'LinkedIn':
      return LinkedIn;
    case 'TrelloDev':
      return TrelloDev;
    case 'TrelloRD':
      return TrelloRD;
    case 'TrelloSales':
      return TrelloSales;
    case 'TrelloSocial':
      return TrelloSocial;
    case 'Twitter':
      return Twitter;
    default:
      return null;
  }
};

const fourButtons = (props) => {
  return (
    <div className="fourButton" >
      <Button variant="contained" id="button" href={props.b1ref} target="_blank">
        <img src={getPic(props.b1pic)} alt={props.b1pic} className='buttonPic' />
      </Button>
      <Button variant="contained" id="button" href={props.b2ref} target="_blank">
        <img src={getPic(props.b2pic)} alt={props.b2pic} className='buttonPic' />
      </Button>
      <Button variant="contained" id="button" href={props.b3ref} target="_blank">
        <img src={getPic(props.b3pic)} alt={props.b3pic} className='buttonPic' />
      </Button>
      <Button variant="contained" id="button" href={props.b4ref} target="_blank">
        <img src={getPic(props.b4pic)} alt={props.b4pic} className='buttonPic' />
      </Button>
    </div>
  )
}

export default fourButtons