import React from 'react';
import { Button } from '@material-ui/core';
import HeaderLogo from './HeaderLogo.png';
import './Header.scss';

const header = () => {
  return (
    <div className='Header'>
      <img src={HeaderLogo} alt="Auxetic" className='Header-Logo' />
      <Button href="https://orders.auxetic.co/" className='Header-Button' color="inherit"> Orders </Button>
      <Button href="https://inventory.auxetic.co/" className='Header-Button' color="inherit"> Inventory </Button>
      <Button href="https://hotlinks.auxetic.co/" className='Header-Button' color="inherit"> Hotlinks </Button>
      <Button href="https://atlas.auxetic.co" className='Header-Button' color="inherit"> Atlas </Button>
    </div>
  );
};

export default header;