import React from 'react';
import auth from './auth'
import Header from './components/Header/Header.jsx'
import FourButton from './components/FourButton/FourButton.jsx'
import LoginPage from './components/LoginPage/LoginPage'

import './App.css'

class App extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      mess:'Hello World',
      authenticated: false, 
      userClaims: {},
    };
  }

  componentWillMount(){
    setTimeout(() => {
      if (auth.auth().currentUser){
        console.log(auth.auth().currentUser)
        auth.auth().currentUser.getIdTokenResult()
          .then(token => {
            const userClaims = token.claims || {};
            this.setState({ 
              authenticated: true,
              userClaims
            })
          })
      } 
    }, 1000)
  }

  render() {
    const { authenticated, userClaims } = this.state
    if (authenticated) {
      return (
        <div id="mainDiv">
          <Header />
          <FourButton 
            b1ref={'https://www.3dhubs.com/user/logout'}
            b1pic={'3DHubs'}
            b2ref={'https://app.3diligent.com/dashboard'}
            b2pic={'3Diligent'}
            b3ref={'https://formlabs.com/dashboard'}
            b3pic={'Formlabs'}
            b4ref={'https://app.hubspot.com/reports-dashboard/4441853/marketing'}
            b4pic={'Hubspot'}
          />
          <div className='divider' />
          {userClaims.hotlinksHideTrello 
            ? null 
            : <FourButton 
              b1ref={'https://trello.com/b/YBPRwxJO/social-media'}
              b1pic={'TrelloSocial'}
              b2ref={'https://trello.com/b/R1o0khoQ/software'}
              b2pic={'TrelloDev'}
              b3ref={'https://trello.com/b/2OzzCMAW/rd-machine'}
              b3pic={'TrelloRD'}
              b4ref={'https://trello.com/b/qccipwAg/sales'}
              b4pic={'TrelloSales'}
            />
          }
          <div className='divider' />
          <FourButton 
            b1ref={'https://www.facebook.com/AuxeticCo/'}
            b1pic={'Facebook'}
            b2ref={'https://www.instagram.com/auxeticco/'}
            b2pic={'Instagram'}
            b3ref={'https://twitter.com/Auxeticco'}
            b3pic={'Twitter'}
            b4ref={'https://www.linkedin.com/company/11157060/admin/updates/'}
            b4pic={'LinkedIn'}
          />
        </div>
      );
    } else {
      return <LoginPage handleLogin={(authenticated) => {
        auth.auth().currentUser.getIdTokenResult()
          .then(token => {
            const userClaims = token.claims || {};
            this.setState({ authenticated, userClaims })
          })
        }
       } />
    }
  }
}

export default App;